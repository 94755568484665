(function () {
    angular
        .module('informaApp')
        .factory('$exceptionHandler', ExceptionHandler);

    function ExceptionHandler($injector) {
        return (exception, cause) => {
            const ExceptionApiService = $injector.get('ExceptionApiService');

            ExceptionApiService.sendError(
                `Exception: ${exception.toString()}\n` +
                `Stack: ${exception.stack && exception.stack}\n` +
                `User Agent: ${navigator.userAgent}\n` +
                `Cause: ${cause}\n`
            );

            console.error(exception);
        };
    }
})();